<template>
  <b-modal :modal-class="{'activeTour' : getGuidedTour.step5}" modal-class="modal_basic modal-create-brand"
           id="modal-create-brand" hide-footer
           hide-header>
    <img @click="$bvModal.hide('modal-create-brand')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <guided-tour v-if="getGuidedTour.step5"></guided-tour>

    <div class="basic_form modal_content">

      <div class="heading">
        <h3 v-if="getBrandAdd._id">Edit Brand</h3>
        <h3 v-else>Create Brand</h3>
      </div>

      <clip-loader v-if="getBrandLoaders.store" :color="color" style="margin: 30px auto;"></clip-loader>

      <template v-else>

        <form class="  clear">
          <div class="input_field brand_image_input">
            <div class="brand_image">
              <div class="brand_image_url" :style="brandImageLink(getBrandAdd.avatar)">
                <clip-loader class="small--loader d-flex align-items-center justify-content-center"
                             v-if="getBrandLoaders.image_upload"
                             :color="color"></clip-loader>
              </div>
              <div class="input_file">

                <input type="file" accept=".png, .jpg, .jpeg, .gif" data-cy="image" class="profile_input"
                       id="brand_input" @change="uploadImageForBrand($event)">
                <label for="brand_input">

                  <i v-if="getBrandAdd._id" v-tooltip.top-center="'Edit Image'"
                     class="far fa-pencil"></i>
                  <i v-else v-tooltip.top-center="'Upload Image'"
                     class="far fa-plus"></i>
                </label>

              </div>
            </div>

            <p class="image_info">Recommended size: Less than 2MB (90 x 90)</p>
          </div>

          <div :class="{'input-field-error': validations.name || validations.nameLength}"
               class="mt-3 input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">Enter your company, product or personal name</div>
              <div class="name">
                <div class="validation_input">
                  <input
                    placeholder="e.g. 'Company ABC' or 'John Smith'" data-cy="b-name"
                    v-model="getBrandAdd.name"
                    @keyup.enter="handleStoreBrand()"
                    type="text">
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.name">{{ messages.name }}</span>
          <span class="input-error" v-else-if="validations.nameLength">{{ messages.nameLength }}</span>
          <div :class="{'input-field-error': validations.url || validations.urlValid || validations.urlLength}"
               class="mt-3 input_icon_text d-flex align-items-center profile__box">
            <div class="content">
              <div class="url">Enter your website or social profile URL</div>
              <div class="name">
                <div class="validation_input">
                  <url
                    v-model="getBrandAdd.url"
                    @onEnter="handleStoreBrand($event)"
                    :validations="validations"
                    v-bind:blur="() => getBrandAdd.url = focusoutConcatenation(getBrandAdd.url)"
                    ref="urlRef"
                    data-cy="b-url"
                    placeholder="e.g. https://mywebsite.com"></url>
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="validations.url">{{ messages.url }}</span>
          <span class="input-error" v-else-if="validations.urlLength">{{ messages.urlLength }}</span>
          <span class="input-error" v-else-if="validations.urlValid">{{ messages.urlValid }}</span>
        </form>
        <div class=" btn_block text-left">

          <template v-if="getGuidedTour.step5">
            <button :disabled="getGuidedTour.step5"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold">
              <span>Save</span>
            </button>
            <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold ">
              <span>Close</span>
            </button>
          </template>

          <template v-else>
            <button v-tooltip="getUploadImageMessage" :disabled="getBrandLoaders.store"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold" data-cy="b-save"
                    :class="{disabled:getBrandLoaders.store}" @click.prevent="handleStoreBrand()">
              <clip-loader v-if="getBrandLoaders.store" :size="size" :color="color"></clip-loader>
              <span>Save</span>
            </button>
            <button @click="$bvModal.hide('modal-create-brand')"
                    type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold">
              <span>Close</span>
            </button>
          </template>

        </div>
      </template>

    </div>
  </b-modal>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'

import GuidedTour from '../../guided-tour/GuidedTour.vue'
import {brandMessages, brandValidations, validLength} from '../../../common/attributes'
import {DARK_PURPLE_LOADER_COLOR} from '../../../common/constants'
import URL from '@/ui/URL.vue'
import {trackBrandCreated} from "../../../common/methods";

export default ({
  components: {
    GuidedTour,
    'url': URL
  },
  data: function () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      modalStatus: {
        keyboard: false,
        value: 'static'
      },
      validations: brandValidations,
      messages: brandMessages,
      validLength: validLength
    }
  },

  computed: {
    ...mapGetters(['getGuidedTour', 'getBrandAdd', 'getBrandLoaders', 'getProfile']),
    getUploadImageMessage () {
      return this.getBrandLoaders.image_upload ? 'Please wait we are uploading your brand image....' : ''
    }
  },

  methods: {
    ...mapActions([
      'uploadImageForBrand',
      'storeBrand'
    ]),
    async handleStoreBrand () {

      if (this.getBrandLoaders.image_upload) {
        this.$store.dispatch('toastNotification', {message: this.getUploadImageMessage, type: 'error'})
        return
      }

      this.validations.name = this.requiredCheck(this.getBrandAdd.name)
      this.validations.url = this.requiredCheck(this.getBrandAdd.url)
      this.getBrandAdd.url = this.urlConcatenation(this.getBrandAdd.url)
      this.validations.urlValid = !this.isValidURL(this.getBrandAdd.url)
      if (!this.validations.url) this.validations.url_valid = !this.isValidURL(this.getBrandAdd.url)

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        if (await this.storeBrand()) {
          this.$bvModal.hide('modal-create-brand')
          if (!this.getBrandAdd._id) {
            // notify with user-maven event
            await trackBrandCreated(this.getProfile)
          }
        }
      }
    }
  },
  watch: {
    'getBrandAdd.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 100)
      }
    },
    'getGuidedTour.step5' (value) {
      if (!value) {
        this.modalStatus.keyboard = false
        this.modalStatus.value = ''
      }
    }
  }
})
</script>
