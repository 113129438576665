<template>
  <tr class="brand_table_head_row text-capitalize">

    <th style="text-align: left;width: 400px;" class="name">Name</th>

    <th style="text-align: center;width: 170px;" class="cursor_pointer sorted_row"
        @click.prevent="changeBrandSort('campaign',getBrand.sort)">Campaigns

      <i v-if="getBrand.sort==='total_cta_asc'"
         class=" table_filter_caret fas fa-caret-up"></i>
      <i v-if="getBrand.sort==='total_cta_desc'"
         class="table_filter_caret fas fa-caret-down"></i>
    </th>

    <th class="cursor_pointer sorted_row" style="text-align: center;width: 200px;"
        @click.prevent="changeBrandSort('link',getBrand.sort)">{{ getSiteDetails.agency_name }} Links

      <i v-if="getBrand.sort==='total_links_asc'"
         class=" table_filter_caret fas fa-caret-up"></i>
      <i v-if="getBrand.sort==='total_links_desc'"
         class="table_filter_caret fas fa-caret-down"></i>
    </th>
    <th style="text-align: center;width: 140px;" class=""
    >Clicks
      <!--          @click.prevent="changeBrandSort('click',getBrand.sort)"-->
      <!--            <i v-if="getBrand.sort==='click_asc'"-->
      <!--               class=" table_filter_caret fas fa-caret-up"></i>-->
      <!--            <i v-if="getBrand.sort==='click_desc'"-->
      <!--               class="table_filter_caret fas fa-caret-down"></i>-->
    </th>

    <th style="text-align: center;width: 150px;" class=""
    >Unique Clicks
      <!--          @click.prevent="changeBrandSort('unique',getBrand.sort)"-->
      <!--            <i v-if="getBrand.sort==='unique_asc'"-->
      <!--               class=" table_filter_caret fas fa-caret-up"></i>-->
      <!--            <i v-if="getBrand.sort==='unique_desc'"-->
      <!--               class="table_filter_caret fas fa-caret-down"></i>-->
    </th>

    <th style="text-align: center;width: 150px;" class=""
    >Conversions
      <!--          @click.prevent="changeBrandSort('conversion',getBrand.sort)"-->
      <!--            <i v-if="getBrand.sort==='conversion_asc'"-->
      <!--               class=" table_filter_caret fas fa-caret-up"></i>-->
      <!--            <i v-if="getBrand.sort==='conversion_desc'"-->
      <!--               class="table_filter_caret fas fa-caret-down"></i>-->
    </th>

    <th style="text-align: center;width: 180px;" class=""
    >Conv. rate
      <!--          @click.prevent="changeBrandSort('conv_rate',getBrand.sort)"-->
      <!--            <i v-if="getBrand.sort==='conversion_rate_asc'"-->
      <!--               class=" table_filter_caret fas fa-caret-up"></i>-->
      <!--            <i v-if="getBrand.sort==='conversion_rate_desc'"-->
      <!--               class="table_filter_caret fas fa-caret-down"></i>-->
    </th>
    <th style="text-align: center;width: 150px;" class="cursor_pointer sorted_row"
        @click.prevent="changeBrandSort('created',getBrand.sort)">Created

      <i v-if="getBrand.sort==='created_asc'"
         class=" table_filter_caret fas fa-caret-up"></i>
      <i v-if="getBrand.sort==='created_desc'"
         class="table_filter_caret fas fa-caret-down"></i>
    </th>

    <th style="width: 150px;" class="text-center">Actions</th>
  </tr>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {brandTypes} from '@/state/modules/mutation-types'

export default ({
  computed: {
    ...mapGetters(['getBrand'])
  },

  methods: {
    ...mapActions(['fetchBrandList', 'fetchBrandAnalytics']),
    async changeBrandSort (type, sort) {
      let sortValue = ''
      switch (type) {
        case 'campaign':
          sortValue = (sort === 'total_cta_desc') ? 'total_cta_asc' : 'total_cta_desc'
          break
        case 'link':
          sortValue = (sort === 'total_links_desc') ? 'total_links_asc' : 'total_links_desc'
          break
        case 'click':
          sortValue = (sort === 'click_desc') ? 'click_asc' : 'click_desc'
          break
        case 'conversion':
          sortValue = (sort === 'conversion_desc') ? 'conversion_asc' : 'conversion_desc'
          break
        case 'created':
          sortValue = (sort === 'created_desc') ? 'created_asc' : 'created_desc'
          break
        case 'conv_rate':
          sortValue = (sort === 'conversion_rate_desc') ? 'conversion_rate_asc' : 'conversion_rate_desc'
          break
        case 'unique':
          sortValue = (sort === 'unique_desc') ? 'unique_asc' : 'unique_desc'
          break
      }
      this.$store.commit(brandTypes.SET_BRAND_SORT_VALUE, sortValue)
      await this.$parent.fetchBrandByFilters();
    }
  }
})
</script>
