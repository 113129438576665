<template>
  <div class="brands_component">
    <confirmation-box
      greenColor="blue"
      class="archiveBrand"
      id="archiveBrand"
      :confirmCallback="archiveBrand"
      confirmText="Yes"
      cancelText="No"
    >
      <template slot="header">
        <h2 v-if="getModalData.status">Archive Brand</h2>
        <h2 v-else>Unarchive Brand</h2>
      </template>

      <template slot="description">

        <p v-if="getModalData.status">All campaigns and {{ getSiteDetails.agency_name }} links
          inside this brand will also be archived but they will
          still be functional.</p>

        <p v-else>All campaigns and {{ getSiteDetails.agency_name }} links inside
          this brand will also be Unarchived.</p>
        <p class="small_sub_text">
          Do you want to continue?
        </p>

        <div class="alert-box  warning">
          <div class="inner_box">
            <div class="checkbox_input_image  ">
              <input
                id="dontShowAgain"
                v-model="getArchiveStatus.status"
                type="checkbox"
              >
              <label
                for="dontShowAgain"
                class="checkbox_left"
              >Do not show this message again.</label>
            </div>

          </div>
        </div>

      </template>

      <!--<template slot="footer">-->
      <!---->
      <!--</template>-->

    </confirmation-box>

    <transition name="slide">
      <!--<sidebar></sidebar>-->

      <div class="container">
        <save-brand ref="brand_modal"></save-brand>
        <div class="brands_component_inner">
          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <div v-if="getProfile.policy.can_add_brands">
                <ui-button
                  :type="'add'"
                  :color="'#ffffff'"
                  :bgColor="'#3988fe'"
                  :text="'New Brand'"
                  :icon="'fal fa-plus'"
                  :iconPosition="'right'"
                  :iconBackground="'rgba(31, 33, 110, 0.35)'"
                  :iconBorderRadius="'50%'"
                  :class="{'activeTour' : getGuidedTour.step4}"
                  v-if="getGuidedTour.step4"
                  v-on:click="openBrandModal()"
                />
                <ui-button
                  :type="'add'"
                  :color="'#ffffff'"
                  :bgColor="'#3988fe'"
                  :text="'New Brand'"
                  :icon="'fas fa-plus'"
                  :iconPosition="'right'"
                  :iconBackground="'rgba(31, 33, 110, 0.35)'"
                  :iconBorderRadius="'50%'"
                  v-else
                  :pulseEffect="getProfile.onboarding == false && getProfile.onboarding_data['brand'] == false"
                  v-on:click="openBrandModal()"
                />
              </div>

            </div>
            <div class="head_right ml-auto d-flex align-items-center justify-content-end">

              <guided-tour v-if="getGuidedTour.step4"></guided-tour>
              <div
                class="datepicker_field   rounded input_field datepicker_input datePicker_parent input_with_icon_right">
                <input
                  v-model="getBrand.date.label"
                  class="brandDatePicker"
                  readonly
                  placeholder="Search by date..."
                  type="text"
                >
                <i class="input_icon fal fa-angle-down"></i>
              </div>

            </div>

          </div>

          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center ">
                <div class="left">
                  <h2>Your Brands</h2>
                  <p>A brand in {{ getSiteDetails.agency_name }} is a unique identity for your business. You can create a brand
                    by adding the name, website/Social profile URL, and logo of your business to
                    track its performance.</p>
                </div>
                <div class="ml-auto right more_width_500 d-flex align-items-center justify-content-end">
                  <div class="  search_input_block no_animation  ">
                    <input
                      v-model="getBrand.search"
                      placeholder="Search Brand"
                      @input="brandSearch"
                      required
                      type="text"
                      maxlength="40"
                      data-cy="b-search"
                    >
                    <i
                      class="fal fa-search"
                      @click.prevent="fetchBrandList({ page: 1 })"
                    ></i>
                  </div>

                  <div class="ml-2 toggle_btn_link with_border ">
                    <div class="toggle_btn_inner">
                      <a
                        @click.prevent="showBrandArchive(false)"
                        class="btn_link"
                        :class="{'active_tab':!getBrand.archive}"
                      >
                        Active Brands
                      </a>
                      <a
                        @click.prevent="showBrandArchive(true)"
                        class="btn_link"
                        :class="{'active_tab':getBrand.archive }"
                      >
                        Archived Brands
                      </a>

                    </div>
                  </div>
                </div>

              </div>
              <div class="box_content">

                <div class="table_container">
                  <table class="table list-content">
                    <thead>
                    <brands-table-head></brands-table-head>
                    </thead>
                    <tbody v-if="getBrandLoaders.retrieve">
                    <tr>
                      <td colspan="9">
                        <p style="padding: 10px;">
                          <clip-loader
                            :size="size"
                            :color="color"
                          ></clip-loader>
                        </p>

                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else-if="getComponentBrandList.length == 0">
                    <tr>
                      <td colspan="9" class="">
                        <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
                            getFilteredMessage('brand', getBrand.search, getBrand.date.label, getBrand.archive)
                          }}</p>
                      </td>
                    </tr>
                    </tbody>
                    <template v-else>
                      <brands-list></brands-list>
                    </template>
                  </table>
                </div>
              </div>

            </div>
            <template v-if="!getBrandLoaders.retrieve">

              <template v-if="getBrand.total_items > getBrand.limit">
                <pagination
                  :clickedMethod="paginate"
                  :resultCount="getBrand.total_items"
                  :itemsPerPage="getBrand.limit"
                  :currentPage="getBrand.page"
                  :totalPagesNumber="getBrand.total_pages"
                ></pagination>
              </template>

            </template>

          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BrandsList from './table/BrandsList'
import BrandsTableHead from './table/BrandsTableHead'

import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Modal from '@/misc/Modal.vue'
import SaveBrand from './SaveBrand.vue'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import Dropdown from '@/ui/Dropdown.vue'

import GuidedTour from '../../guided-tour/GuidedTour.vue'
import { brandTypes, userTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    Headerbar,
    Sidebar,
    Modal,
    SaveBrand,
    GuidedTour,
    ConfirmationBox,
    Dropdown,
    BrandsList,
    BrandsTableHead
  },
  data: function () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      confirmation: {
        archive: false
      },
      previousPage: 1,
      debounce: null
    }
  },

  async created () {
    this.resetBrandFilters(true)
    await this.fetchBrandOnFetchedStatus()
    this.fetchBrandAnalytics()
  },
  mounted () {
    let self = this
    setTimeout(function () {
      self.callDatePicker()
    }, 500)
  },

  computed: {
    ...mapGetters([
      'getBrand',
      'getBrandLoaders',
      'getSubscription',
      'getProfile',
      'getArchiveStatus',
      'getModalData',
      'getComponentBrandList',
      'getBrandList'
    ])
  },

  methods: {
    ...mapActions([
      'setBrandsFetchStatus',
      'setBrandDate',
      'fetchBrandList',
      'fetchBrandAnalytics',
      'archiveBrand',
      'setComponentBrandList'
    ]),
    async showBrandArchive (status) {
      this.$store.commit(brandTypes.SET_BRAND_ARCHIVE_STATUS, status)
      this.fetchBrandByFilters()
    },
    /**
     * Fetching the record on the basis of page number
     * @param page
     * @returns {Promise<void>}
     */
    async paginate (page) {

      this.getBrand.page = page

      // if page is one then no need to check periodic
      if (page !== 1) {
        this.getBrand.periodic = this.isFetchedPeriodic(this.previousPage, page, this.getBrand.periodic)
      }

      // if isAllListFetched true from other source like dropdown then no need to set again isAllListFetched
      if (!this.getBrand.isAllListFetched) {
        // isAllListFetched will only be true only when all record fetched in periodic way
        this.getBrand.isAllListFetched = this.getBrand.periodic && (page >= this.getBrand.total_pages)
      }

      // if previous page is equal to current page then fetch only analytics
      if (this.previousPage === page) {
        this.fetchBrandAnalytics()
        return
      }

      // setting the previousPage
      this.previousPage = JSON.parse(JSON.stringify(page))

      // if pagination is not in periodic way then get record from api
      if (!this.getBrand.periodic && !this.getBrand.isAllListFetched) {
        this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
        await this.fetchBrandList({ page: page })
        await this.setComponentBrandList(this.getBrandList)
        this.fetchBrandAnalytics()
        // resetting the periodic on page 1 and previousPage
        if (page === 1) {
          this.getBrand.periodic = true
          this.previousPage = 1
        }
        return
      }

      // chunck the local record if exist on the basis of pagination otherwise get from DB
      const list = this.getBrandList.slice((page - 1) * this.getBrand.limit, page * this.getBrand.limit)
      if (list && list.length) {
        await this.setComponentBrandList(list)
        this.fetchBrandAnalytics()
        return
      }

      // if no local record found then get from DB
      await this.fetchBrandList({ page: page })
      await this.setComponentBrandList(this.getBrandList.slice((page - 1) * this.getBrand.limit, page * this.getBrand.limit))
      this.fetchBrandAnalytics()

    },
    callDatePicker () {
      let me = this
      $(document).ready(function () {
        $('.brandDatePicker').daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $('.brandDatePicker').on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $('.brandDatePicker').on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })

        $('.brandDatePicker').on('apply.daterangepicker', async function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          } else {
            range = {
              label: picker.chosenLabel,
              value: moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + moment().format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(brandTypes.SET_BRAND_DATE_VALUE, range)
          await me.fetchBrandByFilters()
        })
      })
    },
    /**
     * Fetching the campaign on the basis of date, search, filters
     */
    async fetchBrandByFilters () {
      this.getBrand.isListFetched = false
      this.getBrand.periodic = true
      this.getBrand.isAllListFetched = false
      this.previousPage = 1
      this.$store.commit(brandTypes.SET_BRAND_LISTS, [])
      await this.fetchBrandList({ page: 1 })
      await this.setComponentBrandList(this.getBrandList)
      this.fetchBrandAnalytics()
    },
    async archiveBrandModal (brand, index, userId, status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        brand: brand,
        user_id: userId,
        status: status,
        index: index
      })
      if (this.getProfile.hide_archive_message) {
        this.archiveBrand()
      } else {
        $('#archiveBrand').modal('show')
      }
    },
    openBrandModal () {
      this.saveBrand(this.$refs.brand_modal)
    },
    brandSearch () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchBrandByFilters()
      }, 600)
    }
  }
})
</script>
