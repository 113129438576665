<template>
  <tbody class="list-content-body">

  <tr v-for="(brand,index) in getComponentBrandList">
    <td style="text-align: left;">
      <div class="align_top profile__box" :class="{'overlay_item archived':getBrand.archive}">
        <div class="avatar">
          <div class="brand_img" :style="brandImageLink(brand.avatar)"></div>
        </div>
        <div class="content">
          <div class="word_break name d-inline-block cursor_pointer" v-tooltip="brand.name">
            {{ limitTextLength(brand.name, 34) }}
          </div>
          <div class="clearfix"></div>
          <div class="url cursor_pointer" v-tooltip="brand.url">
            {{ brand.url }}
          </div>
        </div>
      </div>
    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}">
        {{ brand.total_call_to_actions }}
      </div>

    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}">
        {{ commaSeparatedNumber(brand.total_links) }}
      </div>
    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}" v-if="!brand.fetchStats">
        {{ commaSeparatedNumber(brand.clicks) }}
      </div>
      <skeleton v-else></skeleton>
    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}" v-if="!brand.fetchStats">
        {{ commaSeparatedNumber(brand.unique_visits) }}
      </div>
      <skeleton v-else></skeleton>

    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}" v-if="!brand.fetchStats">
        {{ commaSeparatedNumber(brand.conversions) }}
      </div>
      <skeleton v-else></skeleton>

    </td>

    <td style="text-align: center;">
      <div :class="{'overlay_item archived':getBrand.archive}" v-if="!brand.fetchStats">
        {{ brand.ctr }}%
      </div>
      <skeleton v-else></skeleton>

    </td>

    <td style="text-align: center;" class="created_at">
      <div :class="{'overlay_item archived':getBrand.archive}">
        {{ brand.created_at | relativeCaptilize }}
      </div>

    </td>
    <td class=" text-right">
      <div class="actions text-center" v-if="brand.archive">
        <template v-if="brand.policy.edit">

          <i v-tooltip="'Unarchive'" class="far fa-recycle "
             @click.prevent="$parent.archiveBrandModal(brand._id,index,brand.user_id,false)"
             aria-hidden="true" data-cy="b-unarchive"></i>

        </template>
        <template v-else>
          -
        </template>

      </div>
      <template v-else>

        <b-dropdown ref="dropdownActionMenu" right offset="-30"
                    class="table_action_dropdown dropdown-menu-right default_style_dropdown"
                    :no-caret="true">
          <!--d-flex align-items-center-->
          <div class=" dropdown_header no_arrow text-center" slot="button-content">
                         <span class="text">
                             <i class="fal fa-ellipsis-h fw-500"></i>
                         </span>
            <!--<span class="arrow_icon ml-auto">-->
            <!--<i class=" fal fa-angle-down ml-3"></i>-->
            <!--</span>-->
          </div>
          <ul class="inner">
            <li class="list_item">
              <a @click.prevent="brandDetailView(brand)" class="text" data-cy="b-stats">
                <i class="far fa-line-chart"></i> View Stats
              </a>
            </li>

            <template>
              <li class="list_item" v-if="brand.policy.edit">
                <a @click.prevent="handleEditBrand(brand)"
                   target="_blank" href="" data-cy="b-edit">
                  <i class="far  fa-pencil" aria-hidden="true"></i> Edit
                </a>
              </li>

              <li class="list_item" v-if="brand.policy.archive">
                <a
                  target="_blank" href="" @click.prevent="isArchiveBrandAction(brand, index)" data-cy="b-archive">
                  <i class="far  fa-recycle" aria-hidden="true"></i> Archive
                </a>
              </li>
              <li class="list_item" v-if="brand.policy.delete">
                <a @click.prevent="removeBrand({brand: brand._id,index:index,userId: brand.user_id})"
                   target="_blank" href="" data-cy="b-remove">
                  <i class="far  fa-trash" aria-hidden="true"></i> Remove
                </a>
              </li>
            </template>
          </ul>
        </b-dropdown>

      </template>
    </td>
  </tr>

  </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import { brandTypes } from '@/state/modules/mutation-types'

export default ({
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters(['getBrand','getComponentBrandList']),

  },
  methods: {
    ...mapActions(['removeBrand']),
    handleEditBrand (brand) {
      this.$store.commit(brandTypes.SET_BRAND_ADD_VALUE, brand)
      // $('#modal-create-brand').modal('show')
      this.$bvModal.show('modal-create-brand')
    },
    isArchiveBrandAction (brand, index) {
      this.$parent.archiveBrandModal(brand._id, index, brand.user_id, true)
      this.$refs.dropdownActionMenu[index].hide(true)
    }
  }

})
</script>
